import React from "react"
import Layout from "./shared/layout/layout"
import "../../static/assets/scss/pages/page.scss"
import GLOBE from "./shared/components/globe"
import MAP from "./shared/components/globe-2"
import LayoutDark from "./shared/layout/layout-dark"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
import { Helmet } from "react-helmet"

const routes = require("../types/routes")

export default class Procurement extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Compliance</title>
          <link rel="canonical" href={`${process.env.BASE_URL}`} />
          <meta name="title" content="Contingent: Compliance" />
          <meta
            name="description"
            content="Sanctions, AML, Anti-bribery and corruption, and modern
                      slavery requirements are complex and constantly changing,
                      stay ahead with an all-in-one third-party compliance and
                      supplier risk platform."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.BASE_URL}/`} />
          <meta property="og:title" content="Contingent: Compliance" />
          <meta
            property="og:description"
            content="Sanctions, AML, Anti-bribery and corruption, and modern
                      slavery requirements are complex and constantly changing,
                      stay ahead with an all-in-one third-party compliance and
                      supplier risk platform."
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`${process.env.BASE_URL}/`} />
          <meta property="twitter:title" content="Contingent: Compliance" />
          <meta
            property="twitter:description"
            content="Sanctions, AML, Anti-bribery and corruption, and modern
                      slavery requirements are complex and constantly changing,
                      stay ahead with an all-in-one third-party compliance and
                      supplier risk platform."
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic detail"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column centered texture-3"}>
                  <div className={"content"}>
                    <h1 className={"title"}>Compliance</h1>
                    <p className={"description"}>
                      Sanctions, AML, Anti-bribery and corruption, and modern
                      slavery requirements are complex and constantly changing,
                      stay ahead with an all-in-one third-party compliance and
                      supplier risk platform.
                    </p>
                    <div className={"images"}>
                      <object
                        data={"/assets/images/logos/fcpa.svg"}
                        className={"card"}
                      ></object>
                      <object
                        data={"/assets/images/logos/aml.svg"}
                        className={"card"}
                      ></object>
                      <object
                        data={"/assets/images/logos/anti-bribe.svg"}
                        className={"card"}
                      ></object>
                    </div>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column reversed"}>
                  <div className={"content"}>
                    <h2 className={"title"}>
                      Failure to meet regulations can result in reputational and
                      financial damage
                    </h2>
                    <p className={"description"}>
                      Meeting regulatory requirements has become very manual,
                      time consuming, and expensive.
                      <br />
                      <br />
                      To ensure you onboard a new supplier, third-party and
                      partner business efficiently and quickly, we help
                      compliance and onboarding teams unlock their capacity and
                      expertise by providing them with a solution built to
                      comply with sanctions, AML, Anti-bribery and corruption,
                      and Modern Slavery regulations around the world.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                  <object
                    data={"/assets/images/graphics/compliance-1.svg"}
                    className={"card"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <object
                    data={"/assets/images/graphics/compliance-2.svg"}
                    className={"card"}
                  ></object>
                  <div className={"content reversed"}>
                    <h2 className={"title"}>
                      Proactive monitoring without the noise or false positives
                    </h2>
                    <p className={"description"}>
                      We'll automatically alert you when potential compliance
                      risks arise, giving you time to respond proactively, and
                      putting an end to false positives which often lead to
                      teams incurring higher costs due to the additional
                      resources required to manually screen large volumes of
                      false positives.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"content"}>
                    <h2 className={"title"}>
                      Conduct due diligence quickly without sacrificing quality
                    </h2>
                    <p className={"description"}>
                      Empower your team to understand beneficial ownership and
                      control in an instant. Screen against sanctions, global
                      watchlists, PEPs, news and internal proprietary watchlists
                      for a complete 360-degree view and a robust evidential
                      audit trial.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                  <object
                    data={"/assets/images/graphics/compliance-3.svg"}
                    className={"card"}
                  ></object>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-info"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column reversed"}>
                  <object
                    data={"/assets/images/graphics/compliance-4.svg"}
                    className={"card"}
                  ></object>
                  <div className={"content reversed"}>
                    <h2 className={"title"}>
                      Empower decision making with insightful risk analysis
                    </h2>
                    <p className={"description"}>
                      We help teams uncover meaningful insights to inform
                      strategic business decisions, inspire change, realise
                      savings and protect reputations of users, teams and
                      organisations.
                    </p>
                    <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Become more compliant with proactive third-party risk
                    management and monitoring today
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
